import { useMediaQuery } from "@material-ui/core";
import { Theme } from "@mui/material";
import { isMobile } from "react-device-detect";

export function useScreenBreakPoints() {
	return {
		isMobile,
	};
}

export const isIosDevice =
	typeof window !== "undefined" &&
	window.navigator &&
	window.navigator.platform &&
	(/iP(ad|hone|od)/.test(window.navigator.platform) ||
		(window.navigator.platform === "MacIntel" && window.navigator.maxTouchPoints > 1));
