import { PolicyLogStatus } from "shared/enums/PolicyLogStatus";
import { ProcessState, ProcessType, QuizSessionStatus } from "./AuditProcess";
import { DateInterval, Guid, IdName, JSONString } from "./HelperModels";
import { UserRoles } from "app/auth/UserRoles";

export enum OrderType {
	ASC = 1,
	DESC = 2,
	CREATION = 3,
	CREATION_DESC = 4,
}

export type Filter = {
	keyword: string;
	orderType?: OrderType;
	pageNumber: number;
};

export type InfiniteFilter = Filter & {
	hasMore: boolean;
};

export type ProcessesFilter = Filter & {
	practiceIds: Guid[];
	teamIds: Guid[];
	templateGroupId: Guid;
	states: ProcessState[];
	owners: Guid[];
	betweenDates: DateInterval;
	processTypes: ProcessType[];
	quizStatus: QuizSessionStatus[];
};

export type ProcessTemplateFilterTeamLead = Pick<ProcessesFilter, "keyword" | "pageNumber"> & {
	teamIds: Guid[];
	processTemplateTypes: ProcessType[];
	overdueReviewed: boolean;
};

export type PolicyLogFilter = Filter & {
	policyIds: string[];
	ownerIds: string[];
	startDate: JSONString<Date> | null;
	statuses: PolicyLogStatus[];
	practiceIds: string[];
	teamIds: Guid[];
};

export type UserRoleGroupFilter = Filter & {
	role: Exclude<UserRoles, UserRoles.SuperAdmin>;
};

export type BaseLookupFilter = Filter & { defaultValues?: Guid[]; organizationId: Guid };
