import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OPTIONS } from "shared/components/TimePickers/DayPicker";
import { DAYS_IN_MONTH, MINUTES_IN_DAY } from "shared/constants/time";
import { EntityPriority } from "shared/enums/EntityPriority";
import { EntityState } from "shared/enums/EntityState";
import { ProcessTemplate, Step, TemplateVariant } from "shared/models/ProcessTemplate";
import { Quiz, QuizQuestion, QuizQuestionOption } from "shared/models/Quiz";
import { deepCopyFrom } from "shared/services/deepCopyFrom";
import { UpdateProperty } from "shared/services/processTemplateReduxService";

export type ProcessTemplateSliceType = ProcessTemplate & { isLoading: boolean };

const INITIAL_STATE: ProcessTemplateSliceType = {
	id: "",
	name: "",
	description: "",
	attachments: [],
	map: null,
	roles: [],
	tags: [],
	teams: [],
	policies: [],
	variant: TemplateVariant.V2,
	versionGroupId: "",
	versionNumber: 0,
	notificationPeriodInDays: OPTIONS.OFF.id,
	trainingPeriodInDays: DAYS_IN_MONTH * 6,
	reviewPeriodInDays: DAYS_IN_MONTH * 6,
	state: EntityState.Draft,
	priority: EntityPriority.Low,
	steps: [],
	quizzes: [],
	organizationId: "",
	organizationName: "",
	reviewedBy: {
		firstName: "",
		lastName: "",
	},
	reviewedOn: new Date(),
	categoryId: "",
	sla: 1,
	expectedTimeToCompleteInMinutes: MINUTES_IN_DAY,
	isIdentifierRequired: false,
	isSignatureRequired: false,
	isChecklist: false,
	expertId: "",
	isGlobal: false,
	approvers: [],
	approversHistory: [],
	allowPageDownload: true,
	startedCopiedProcesses: 0,
	copiedFromGlobalTemplateId: undefined,
	modifiedBy: null,
	modifiedOn: null,
	isLoading: false,
	hasQuiz: false,
	canEdit: true,
	editedBy: "",
	canReview: false,
};

export const processTemplateSlice = createSlice({
	name: "processTemplate",
	initialState: INITIAL_STATE,
	reducers: {
		updateProcessTemplate: (state, action: PayloadAction<Partial<ProcessTemplate>>) => {
			return { ...state, ...action.payload };
		},
		setProcessTemplateSteps: (
			state,
			action: PayloadAction<{ steps: Step[]; modifiedData: Partial<ProcessTemplate> }>
		) => {
			return { ...state, ...action.payload.modifiedData, steps: action.payload.steps };
		},
		addProcessTemplateStep: (
			state,
			action: PayloadAction<{ step: Step; modifiedData: Partial<ProcessTemplate> }>
		) => {
			return {
				...state,
				...action.payload.modifiedData,
				steps: [...state.steps, action.payload.step],
			};
		},
		updateProcessTemplateStep: (
			state,
			action: PayloadAction<{
				step: Partial<Step>;
				modifiedData: Partial<ProcessTemplate>;
				property: UpdateProperty;
			}>
		) => {
			const newSteps = state.steps.map((value) => {
				if (value.id === action.payload.step.id) {
					const updatedValue = { ...value };

					switch (action.payload.property) {
						case UpdateProperty.Attachments:
							updatedValue.attachments = action.payload.step.attachments ?? [];
							break;
						case UpdateProperty.LinkedSOP:
							updatedValue.linkedProcessTemplateVersionGroupId =
								action.payload.step.linkedProcessTemplateVersionGroupId;
							updatedValue.linkedProcessTemplateVersionGroupName =
								action.payload.step.linkedProcessTemplateVersionGroupName;
							break;
						case UpdateProperty.Title:
							updatedValue.title = action.payload.step.title ?? "";
							break;
						case UpdateProperty.Description:
							updatedValue.description = action.payload.step.description ?? "";
							break;
						case UpdateProperty.Skipable:
							updatedValue.isSkippable = action.payload.step.isSkippable ?? false;
							break;
						case UpdateProperty.EndStep:
							updatedValue.isEndStep = action.payload.step.isEndStep ?? false;
							break;
						case UpdateProperty.Links:
							updatedValue.isConditional = action.payload.step.isConditional ?? false;
							updatedValue.linkedOptions = action.payload.step.linkedOptions ?? [];
							updatedValue.linkedSteps = action.payload.step.linkedSteps ?? [];
							break;
						case UpdateProperty.LinkedOptions:
							updatedValue.conditionText = action.payload.step.conditionText;
							updatedValue.linkedOptions = action.payload.step.linkedOptions ?? [];
							break;
						case UpdateProperty.LinkedSteps:
							updatedValue.linkedSteps = action.payload.step.linkedSteps ?? [];
							break;
						case UpdateProperty.Role:
							updatedValue.role = action.payload.step.role ?? null;
							break;
						case UpdateProperty.IsConditional:
							updatedValue.isConditional = action.payload.step.isConditional ?? false;
							break;
					}

					return updatedValue;
				}
				return value;
			});
			return {
				...state,
				...action.payload.modifiedData,
				steps: newSteps,
			};
		},

		/// Quizzes
		setProcessTemplateQuizzes: (
			state,
			action: PayloadAction<{ quizzes: Quiz[]; modifiedData: Partial<ProcessTemplate> }>
		) => {
			return { ...state, ...action.payload.modifiedData, quizzes: action.payload.quizzes };
		},
		addProcessTemplateQuiz: (
			state,
			action: PayloadAction<{ quiz: Quiz; modifiedData: Partial<ProcessTemplate> }>
		) => {
			return {
				...state,
				...action.payload.modifiedData,
				quizzes: [...state.quizzes, action.payload.quiz],
			};
		},
		updateProcessTemplateQuiz: (
			state,
			action: PayloadAction<{ quiz: Partial<Quiz>; modifiedData: Partial<ProcessTemplate> }>
		) => {
			return {
				...state,
				...action.payload.modifiedData,
				quizzes: state.quizzes.map((value) => {
					if (value.id === action.payload.quiz.id) {
						return { ...value, ...action.payload.quiz };
					}
					return value;
				}),
			};
		},
		addProcessTemplateQuestion: (
			state,
			action: PayloadAction<{
				question: Partial<QuizQuestion>;
				quizId: string;
				modifiedData: Partial<ProcessTemplate>;
			}>
		) => {
			return {
				...state,
				quizzes: state.quizzes.map((quiz) => {
					if (quiz.id === action.payload.quizId) {
						return {
							...quiz,
							questions: [...quiz.questions, deepCopyFrom(action.payload.question)],
						};
					}
					return quiz;
				}),
			};
		},
		removeProcessTemplateQuestion: (
			state,
			action: PayloadAction<{
				questionId: string;
				quizId: string;
				modifiedData: Partial<ProcessTemplate>;
			}>
		) => {
			return {
				...state,
				quizzes: state.quizzes.map((quiz) => {
					if (quiz.id === action.payload.quizId) {
						return {
							...quiz,
							questions: quiz.questions.filter(
								(question) => question.id !== action.payload.questionId
							),
						};
					}
					return quiz;
				}),
			};
		},
		updateProcessTemplateQuestion: (
			state,
			action: PayloadAction<{
				question: Partial<QuizQuestion>;
				quizId: string;
				modifiedData: Partial<ProcessTemplate>;
			}>
		) => {
			return {
				...state,
				quizzes: state.quizzes.map((quiz) => {
					if (quiz.id === action.payload.quizId) {
						return {
							...quiz,
							questions: quiz.questions.map((question) => {
								if (question.id === action.payload.question.id) {
									return { ...question, ...action.payload.question };
								}
								return question;
							}),
						};
					}
					return quiz;
				}),
			};
		},

		addProcessTemplateOption: (
			state,
			action: PayloadAction<{
				option: Partial<QuizQuestionOption>;
				questionId: string;
				modifiedData: Partial<ProcessTemplate>;
			}>
		) => {
			return {
				...state,
				quizzes: state.quizzes.map((quiz) => {
					return {
						...quiz,
						questions: quiz.questions.map((question) => {
							if (question.id === action.payload.questionId) {
								return {
									...question,
									options: [...question.options, deepCopyFrom(action.payload.option)],
								};
							}
							return question;
						}),
					};
				}),
			};
		},
		updateProcessTemplateOption: (
			state,
			action: PayloadAction<{
				option: Pick<QuizQuestionOption, "id" | "title" | "isCorrect">;
				questionId: string;
				modifiedData: Partial<ProcessTemplate>;
			}>
		) => {
			return {
				...state,
				quizzes: state.quizzes.map((quiz) => {
					return {
						...quiz,
						questions: quiz.questions.map((question) => {
							if (question.id === action.payload.questionId) {
								return {
									...question,
									options: question.options.map((option) => {
										if (option.id === action.payload.option.id) {
											return { ...option, ...action.payload.option };
										}
										return option;
									}),
								};
							}
							return question;
						}),
					};
				}),
			};
		},
		removeProcessTemplateOption: (
			state,
			action: PayloadAction<{
				questionId: string;
				optionId: string;
				modifiedData: Partial<ProcessTemplate>;
			}>
		) => {
			return {
				...state,
				quizzes: state.quizzes.map((quiz) => {
					return {
						...quiz,
						questions: quiz.questions.map((question) => {
							if (question.id === action.payload.questionId) {
								return {
									...question,
									options: question.options.filter(
										(option) => option.id !== action.payload.optionId
									),
								};
							}
							return question;
						}),
					};
				}),
			};
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			return { ...state, isLoading: action.payload };
		},
		defaultProcessTemplate: () => {
			return INITIAL_STATE;
		},
	},
});

export const {
	updateProcessTemplate,
	setProcessTemplateSteps,
	updateProcessTemplateStep,
	addProcessTemplateStep,
	defaultProcessTemplate,
	setLoading,

	setProcessTemplateQuizzes,
	addProcessTemplateQuiz,
	updateProcessTemplateQuiz,
	addProcessTemplateQuestion,
	updateProcessTemplateQuestion,
	addProcessTemplateOption,
	updateProcessTemplateOption,
	removeProcessTemplateOption,
	removeProcessTemplateQuestion,
} = processTemplateSlice.actions;
